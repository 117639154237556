import React from 'react';
import styles from './PageTile.styles.scss';
import cn from 'classnames';

const PageTile = ({
  title,
  iconName,
  handleClick,
  disabled,
  onHover,
  hoverStyles,
  titleClassName,
}) => {
  let image;

  try {
    image = require(`../../assets/png/${iconName}.png`);
  } catch (e) {
    image = null;
  }

  return (
    <div
      className={cn(
        styles.container,
        hoverStyles() ? styles.hoveredContainer : '',
      )}
      onClick={handleClick}
      disabled={disabled}
      onMouseOver={onHover}
      onMouseLeave={onHover}
    >
      <div
        className={cn(
          styles.icon,
          disabled ? styles.disabledIcon : '',
          styles.imageDiv,
          hoverStyles() ? styles.hoveredContainer : '',
        )}
      >
        {image && <img src={image} />}
      </div>
      <div
        className={cn(
          styles.titleDiv,
          hoverStyles() ? styles.hoveredContainer : '',
        )}
      >
        <p
          className={cn(
            styles.title,
            disabled ? styles.disableTitle : '',
            titleClassName,
          )}
        >
          {title}
        </p>
      </div>
    </div>
  );
};

export default React.memo(PageTile);
