import React, { useState, useEffect } from 'react';
import ServiceProviderTable from './ServiceProviderTable';
import { Modal } from '../../../components/shared/Modal';
import { Button } from '../../../components/shared/Button';
import { useTranslation } from 'react-i18next';

const ServiceProviderTableContainer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([
    {
      legalEntityName: 'Name',
      referenceNumber: '12354673',
      status: 'PENDING',
    },
  ]);

  const { i18n, t } = useTranslation();

  const onProceedClick = () => {
    console.log('proceed');
    setOpenModal(true);
  };

  const approvedData = [
    {
      legalEntityName: 'Name',
      referenceNumber: '12354673',
      status: 'APPROVED',
    },
  ];

  const pendingCertificate = [
    {
      legalEntityName: 'Name',
      referenceNumber: '12354673',
      status: 'PENDING_CERT',
    },
  ];

  const handleOpenInNewTab = () => {
    const newUrl = `/${i18n.language}/solution-registration/initiate-new-registration`;
    const fullUrl = `${window.location.origin}${newUrl}`;
    window.open(fullUrl, '_blank', 'noopener,noreferrer');
    setOpenModal(false);
    setTimeout(() => {
      setTableData(approvedData);
    }, 5000);
  };

  useEffect(() => {
    setTimeout(() => {
      setTableData(pendingCertificate);
    }, 5000);
  }, []);

  return (
    <>
      <ServiceProviderTable onProceedClick={onProceedClick} data={tableData} />
      <Modal
        withCloseIcon={true}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <p className="mb-4">
          {t('service_provider_dashboard.congrats')}{' '}
          <strong>OTA-ASP-2025-001</strong>
        </p>
        <p className="mb-2">
          {t('service_provider_dashboard.you_can_proceed')}{' '}
        </p>
        <p className="mb-1">
          <strong>{t('service_provider_dashboard.support_resources')}</strong>
        </p>
        <p className="mb-1">{t('service_provider_dashboard.to_assist')}</p>
        <ul className="mb-4">
          <li>- {t('service_provider_dashboard.user_manuals')}</li>
          <li>- {t('service_provider_dashboard.setup_guides')}</li>
          <li>- {t('service_provider_dashboard.video_tutorials')}</li>
        </ul>
        <p className="mb-4">
          {t('service_provider_dashboard.further_assistance')}
        </p>
        <div className="d-flex justify-content-center">
          <Button
            style={{ fontSize: '15px' }}
            onClick={() => handleOpenInNewTab()}
          >
            {t('service_provider_dashboard.go_to_onboarding')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ServiceProviderTableContainer);
