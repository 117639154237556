import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PAGES, ROUTES } from './EInvoicingPortal.config';
import { Dashboard } from '../../components/Dashboard';
import { useKeycloak } from '../../hooks/useKeycloak';

const EInvoicingPortal = () => {
  const [pages, setPages] = useState([]);
  const { t } = useTranslation();
  const { roles } = useKeycloak();

  useEffect(() => {
    if (roles && roles.length > 0) {
      setPages(PAGES(roles));
    }
  }, [roles]);

  return (
    <Dashboard
      isEInvoicing={true}
      title={t('e_invoicing_portal_header')}
      routes={ROUTES}
      pages={pages}
      tileId={'e_invoicing'}
      withBreadcrumb={false}
    />
  );
};

export default React.memo(EInvoicingPortal);
