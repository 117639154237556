import React from 'react';
import { MainLayout } from '../MainLayout';
import { PagesHeader } from '../shared/PagesHeader';
import { TilesWrapper } from '../shared/TilesWrapper';
import { Tile } from '../shared/Tile';
import { PageTile } from '../PageTile';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const Dashboard = ({
  title,
  withBreadcrumb,
  routes,
  pages,
  isEInvoicing,
  tileId,
  isTaxReturn,
}) => {
  const { t } = useTranslation();

  const tileCalcs = () => {
    if (pages.length > 4) {
      return {
        width: `col-lg-${Math.floor(12 / pages.length)}`,
        aspectRatio: 1,
      };
    } else {
      return { width: 'col-lg-3', aspectRatio: 1.4 };
    }
  };

  return (
    <MainLayout eInvoicing={isEInvoicing}>
      <PagesHeader
        title={title}
        withBreadcrumb={withBreadcrumb}
        routes={routes}
        isTaxReturn={isTaxReturn}
      />
      <TilesWrapper>
        {pages.map(
          (page, i) =>
            !page.hide && (
              <Tile
                className={classNames(
                  'col-md-6 col-sm-12 my-5 bg-white',
                  tileCalcs().width,
                )}
                key={i}
                id={tileId + i}
                aspectRatio={tileCalcs().aspectRatio}
              >
                <PageTile
                  redirectUrl={page.redirectUrl}
                  iconName={page.iconName}
                  title={t(page.title)}
                  buttonName={t(page.buttonName)}
                  titleClassName={'mx-5'}
                  disabled={page.disabled}
                />
              </Tile>
            ),
        )}
      </TilesWrapper>
    </MainLayout>
  );
};

export default React.memo(Dashboard);
