import React from 'react';

import { EInvoicingPortal } from '../../pages/EInvoicingPortal';
import { SolutionRegistration } from '../../pages/SolutionRegistration';
import { InitiateNewRegistration } from '../../pages/InitiateNewRegistration';
import { ManageExistingSolutions } from '../../pages/ManageExistingSolutions';
import { Dashboard } from '../../pages/Dashboard';
import { CreateInvoice } from '../../pages/SmePortal/pages/CreateInvoice';
import { InvoiceList } from '../../pages/SmePortal/pages/InvoiceList';
import { ViewInvoice } from '../../pages/SmePortal/pages/ViewInvoice';

import { TaxReturn } from '../../pages/TaxReturn';
import { TaxReturnDetails } from '../../pages/TaxReturn/TaxReturnDetails';
import { ReturnHome } from '../../pages/TaxReturn/pages/ReturnHome';
import { PurchasesRegister } from '../../pages/TaxReturn/pages/PurchasesRegister';
import { SalesRegister } from '../../pages/TaxReturn/pages/SalesRegister';

import { SmePortal } from '../../pages/SmePortal';
import { InnerDashboard as SmeInnerDashboard } from '../../pages/SmePortal/pages/InnerDashboard';

import { InvoiceView } from '../../pages/InvoiceView';
import { ServiceProvider } from '../../pages/ServiceProvider';
import { ServiceProviderTable } from '../../pages/ServiceProvider/ServiceProviderTable';

export const routeConfig = {
  showSolutionRegistration: true,
  showDashboard: true,
  showTaxReturn: true,
  showInvoiceView: true,
  showServiceProvider: false,
  showSme: true,
  smeTypes: ['standard', 'simplified'],
};

export const ROUTES = ({ KEYCLOAK_REALM, baseRoute, TAX_COUNTRY, config }) => [
  {
    path: `${baseRoute}`,
    element: null,
    nestedRoutes: [
      {
        path: '',
        element: <EInvoicingPortal KEYCLOAK_REALM={KEYCLOAK_REALM} />,
        nestedRoutes: null,
      },
      config.showSolutionRegistration && {
        path: 'solution-registration',
        element: null,
        nestedRoutes: [
          { path: '', element: <SolutionRegistration />, nestedRoutes: null },
          {
            path: 'initiate-new-registration',
            element: <InitiateNewRegistration />,
            nestedRoutes: null,
          },
          {
            path: 'manage-existing-solution',
            element: <ManageExistingSolutions />,
            nestedRoutes: null,
          },
        ],
      },
      config.showDashboard && {
        path: 'dashboard',
        element: <Dashboard />,
        nestedRoutes: null,
      },
      config.showSme && {
        path: 'sme',
        element: null,
        nestedRoutes: [
          {
            path: '',
            element: <SmePortal />,
            nestedRoutes: null,
          },
          ...config.smeTypes.map((type) => ({
            path: type,
            element: null,
            nestedRoutes: [
              {
                path: '',
                element: <SmeInnerDashboard type={type} />,
                nestedRoutes: null,
              },
              {
                path: 'invoice',
                element: (
                  <CreateInvoice
                    taxCountry={TAX_COUNTRY}
                    type="e_invoice"
                    category={type}
                  />
                ),
                nestedRoutes: null,
              },
              {
                path: 'note',
                element: (
                  <CreateInvoice
                    taxCountry={TAX_COUNTRY}
                    type="creditDebit"
                    category={type}
                  />
                ),
                nestedRoutes: null,
              },
            ],
          })),
          {
            path: 'view-list',
            element: null,
            nestedRoutes: [
              {
                path: '',
                element: <InvoiceList />,
                nestedRoutes: null,
              },
              {
                path: 'view-invoice/:id/:type',
                element: <ViewInvoice />,
                nestedRoutes: null,
              },
            ],
          },
        ],
      },
      config.showTaxReturn && {
        path: 'tax-return',
        element: null,
        nestedRoutes: [
          { path: '', element: <ReturnHome />, nestedRoutes: null },
          {
            path: 'display-submit-returns',
            element: <TaxReturn />,
            nestedRoutes: null,
          },
          {
            path: 'return-details',
            element: <TaxReturnDetails />,
            nestedRoutes: null,
          },
          {
            path: 'purchase-register',
            element: <PurchasesRegister />,
            nestedRoutes: null,
          },
          {
            path: 'sales-register',
            element: <SalesRegister />,
            nestedRoutes: null,
          },
        ],
      },
      config.showInvoiceView && {
        path: 'invoice-view',
        element: <InvoiceView />,
        nestedRoutes: null,
      },
      config.showServiceProvider && {
        path: 'service-provider',
        element: null,
        nestedRoutes: [
          { path: '', element: <ServiceProvider />, nestedRoutes: null },
          {
            path: 'dashboard',
            element: <ServiceProviderTable />,
            nestedRoutes: null,
          },
        ],
      },
    ].filter(Boolean),
  },
];
