export const PAGES = (roles) => [
  {
    title: 'sme',
    iconName: 'sme',
    buttonName: 'invoicing',
    redirectUrl: 'sme',
    hide: roles.indexOf('sme_portal') === -1,
  },
  {
    title: 'solution_registration',
    iconName: 'solReg',
    buttonName: 'register',
    redirectUrl: 'solution-registration',
    hide: roles.indexOf('taxpayer') === -1,
  },
  {
    title: 'service_provider',
    iconName: 'serviceProvider',
    redirectUrl: 'service-provider',
    hide: roles.indexOf('service_provider') === -1,
  },
  {
    title: 'dashboard_and_statistics',
    hide: roles.indexOf('taxpayer') === -1,
    iconName: 'dashStat',
    buttonName: 'statistics',
    redirectUrl: 'dashboard',
  },
  {
    title: 'invoice_view',
    iconName: 'viewInvoices',
    redirectUrl: 'invoice-view',
    hide: roles.indexOf('service_provider') === -1,
  },
];
export const ROUTES = [
  {
    label: 'breadcrumb_e_invoicing',
    link: '',
  },
];
